.c_gallery {
    position: fixed;
    top: 0; bottom: 0; left: 0; right: 0;
    width: 100%;
    height: 100%;

	background-color: rgba(255,255,255,0.98);

	margin: 0px auto;
	padding-top: 90px;
	padding-bottom: 90px;

	padding-left: 45px;
	padding-right: 45px;

	opacity:0;
	z-index: 999;
	pointer-events:none;

	&.is-visible {
		opacity:1;
		pointer-events: auto;
	}

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@include transition(opacity, $fast, ease);

}
	
.c_gallery__image-container {
	position:relative;
	width:100%;
	height:100%;
	text-align: center;
	outline: none;
}

.c_gallery__image-container	figure {
	position:absolute;
	top:0; left:0; bottom:0; right:0;
	outline: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;  
}

.c_gallery__image-container	figure.current {
	figcaption {
		visibility: visible;
	}
	img {
		opacity: 1;
	}
}


.c_gallery__image-container	figcaption {
	position:fixed;
	bottom: 10px;
	left: 50%;
	@include transform(translateX(-50%));

	@include mq($br-medium) {
		width: 100%;
		bottom: -60px;
	}

	visibility: hidden;
}

.c_gallery__image-container	figure img {
	position:absolute;
	top:50%; left:50%; bottom:0; right:0;
	@include transform(translate(-50%,-50%));

	width:auto;
	height:auto;
	max-width: 100%;
	max-height: 100%;
	outline: none;
	//cursor: pointer;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;  

	opacity:0;
	@include transition(opacity, $normal, $ease);

	/*
	&.loaded {
		opacity: 1;
	}
	*/
}

.c_gallery__trigger {
	position: fixed;
	top:0;
	bottom:0;
	width:20vw;
	min-width: 60px;
	height:100vh;
	cursor: pointer;
	z-index: 1001;
}

.c_gallery__trigger--prev {
	left:0;
}

.c_gallery__trigger--next {
	right:0;
}

.c_gallery__btn {
	position:fixed;
	z-index: 1001;
	@include serif;
	line-height: 1em;
	text-align: center;
	white-space: nowrap;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;


	@include transition(opacity, $fast, $ease);

}

.c_gallery__btn--close {
	@include transform(rotate(90deg));
    -ms-transform-origin: top right;
    -webkit-transform-origin: top right;
    transform-origin: top right;

    top: 60px;
    right: 8px;

   	@include mq($br-medium) {
		top: 50px;
		right: 0px;
	}


}

.c_gallery__btn--prev {

	@include transform(rotate(90deg));
    -ms-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;

    width: 100vh;
    height: 0;

    top: 0;
    left: 35px;

   	@include mq($br-medium) {
		left:30px;
	}


    background-color: #ff0000;
    pointer-events: none;

    opacity: 0;

    &.is-visible {
    	opacity: 1;
    }

}


.c_gallery__btn--next {

	@include transform(rotate(90deg));
    -ms-transform-origin: top right;
    -webkit-transform-origin: top right;
    transform-origin: top right;

    width: 100vh;
    height: 0;

    bottom: 0;
    right: 8px;


   	@include mq($br-medium) {
		right:0px;
	}

    background-color: #ff0000;
    pointer-events: none;

    opacity: 0;

    &.is-visible {
    	opacity: 1;
    }
    
}

