// Fonts

@font-face {
  font-family: 'FaktPro-Blond';
  src: url('../fonts/FaktPro-Blond.eot?#iefix') format('embedded-opentype'),  url('../fonts/FaktPro-Blond.otf')  format('opentype'),
	     url('../fonts/FaktPro-Blond.woff') format('woff'), url('../fonts/FaktPro-Blond.ttf')  format('truetype'), url('../fonts/FaktPro-Blond.svg#FaktPro-Blond') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FaktPro-BlondItalic';
  src: url('../fonts/FaktPro-BlondItalic.eot?#iefix') format('embedded-opentype'),  url('../fonts/FaktPro-BlondItalic.otf')  format('opentype'),
       url('../fonts/FaktPro-BlondItalic.woff') format('woff'), url('../fonts/FaktPro-BlondItalic.ttf')  format('truetype'), url('../fonts/FaktPro-BlondItalic.svg#FaktPro-BlondItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FaktPro-Medium';
  src: url('../fonts/FaktPro-Medium.eot?#iefix') format('embedded-opentype'),  url('../fonts/FaktPro-Medium.otf')  format('opentype'),
       url('../fonts/FaktPro-Medium.woff') format('woff'), url('../fonts/FaktPro-Medium.ttf')  format('truetype'), url('../fonts/FaktPro-Medium.svg#FaktPro-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PortraitCond-Regular';
  src: url('../fonts/PortraitCond-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/PortraitCond-Regular.otf')  format('opentype'),
	     url('../fonts/PortraitCond-Regular.woff') format('woff'), url('../fonts/PortraitCond-Regular.ttf')  format('truetype'), url('../fonts/PortraitCond-Regular.svg#PortraitCond-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

