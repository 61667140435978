//Container
//Acts like a wrapper/inner div

.o-section {
	@include section();

	&.is-calc-vh {
		@include mq-min-h(500px) {
			margin:0;
			height: 33.33vh;

			> div {
				width:100%;
				height:auto;
				position:absolute;
				top:50%;
				left:50%;
				@include transform(translate(-50%,-50%));
			}
		}
	}
}

.o-section--less-padding {
	@include section--less-padding();
}

.o-section--extra-padding {
	@include section--extra-padding();
}
