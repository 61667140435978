.o-image {
	position: relative;
	display:block;

	.o-caption {
		opacity: 0;
	}

	.o-caption--visible {
		opacity: 1;
	}

}

.o-image img {
	opacity: 0;
	@include transition(opacity, $fast, $ease);
}

.o-image.is-loaded img {
	opacity: 1;
}

html.no-js {
	.o-image img {
		opacity: 1;
	}
}

.o-image .o-image__loader {
	background-color: #000000;

	position: absolute;
	top:0; left: 0; right:0; bottom:0;
	width: 100%;
	height: 100%;

	opacity: 1;
	@include transition(opacity, $fast, $ease);
}

.o-image.is-loaded .o-image__loader {
	opacity: 0;
}


.o-image:hover {
	.o-caption {
		opacity: 1;
	}

	.o-image__curtain {
		opacity: 0;
	}
}

.o-image__curtain {
	background-color: #000;

	position: absolute;
	top:0; left: 0; right:0; bottom:0;
	width: 100%;
	height: 100%;

	opacity: 0.9;
	@include transition(opacity, $fast, $ease);

}

/*
html.is-touchdevice {
	.o-image__curtain {
		opacity: 0;
	}
}
*/

.o-caption,
figcaption {
	@include parent-behaviour;
	@include sans--small;
	margin-top: 7px;
	float:right;
}

.o-caption--left,
figcaption.o-caption--left {
	float:left !important;
}

.o-caption--center,
figcaption.o-caption--center {
	float:none !important;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.o-caption--right,
figcaption.o-caption--right {
	float:right !important;
}

.o-image--landscape {
	padding-bottom: 66.66%;
}

.o-image--portrait {
	//padding-bottom: calc( calc(100%*2 + 1rem*2) * (2/3) );
	//padding-bottom: calc( (200% + 2rem) * (2/3) );
	height: 100%;
}