// Titles
// Title classes

.o-title {
    margin-bottom: .5em;

    &.no-padding {
    	margin-bottom: 0;
    }
}

.o-title--h1 {
    @include serif();
}

.o-title--h2 {
    @include sans--large();
}

.o-title--h3 {
    @include sans--bold();
    margin-bottom: 1em;
}