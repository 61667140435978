//Utility classes for visibility states

.u-hidden {
  display: none !important;
}

.u-visible {
  display: block !important;
}

.u-visible-inline {
  display: inline !important;
}

.u-visible-inline-block {
  display: inline-block !important;
}

.u-visible-flex {
  display: flex !important;
}

.u-visible-inline-flex {
  display: inline-flex !important;
}