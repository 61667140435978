// Font settings

$sans-serif: 'FaktPro-Blond', Helvetica, Arial, 'Lucida Grande', sans-serif;
$sans-serif--bold: 'FaktPro-Medium', Helvetica, Arial, 'Lucida Grande', sans-serif;
$sans-serif--italic: 'FaktPro-MediumItalic', Helvetica, Arial, 'Lucida Grande', sans-serif;
$serif: 'PortraitCond-Regular', Helvetica, Arial, 'Lucida Grande', sans-serif;
$normal: 400;

// Typography

@mixin sans() {
  font-family: $sans-serif;
  font-size: 16px;
  line-height: 1.33em;

  @include mq($br-medium) {
    font-size: 15px;
    line-height: 1.33em;
  }
}

@mixin sans--bold() {
  font-family: $sans-serif--bold;
}

@mixin sans--italic() {
  font-family: $sans-serif--italic;
}

@mixin sans--small() {
  font-family: $sans-serif;
  font-size: 13px;
  line-height: 1.33em;

  @include mq($br-medium) {
    font-size: 12px;
    line-height: 1.33em;
  }
}

@mixin sans--large() {
  font-family: $sans-serif;
  font-size: 20px;
  line-height: 1.33em;

  @include mq($br-medium) {
    font-size: 18px;
    line-height: 1.33em;
  }
}

@mixin serif() {
  font-family: $serif;
  font-size: 32px;
  line-height: 1.33em;
  text-transform: uppercase;

  @include mq($br-medium) {
    font-size: 26px;
    line-height: 1.33em;
  }
}

@mixin serif--header-five-line() {
  font-family: $serif;
  font-size: calc(11.6vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-four-line() {
  font-family: $serif;
  font-size: calc(13vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-three-line() {
  font-family: $serif;
  font-size: calc(14.25vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-two-line() {
  font-family: $serif;
  font-size: calc(16.25vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-one-line() {
  font-family: $serif;
  font-size: calc(18.75vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

//Mobile version
@mixin serif--header-five-line--mobile() {
  font-family: $serif;
  font-size: calc(9.6vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-four-line--mobile() {
  font-family: $serif;
  font-size: calc(11vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-three-line--mobile() {
  font-family: $serif;
  font-size: calc(12.25vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-two-line--mobile() {
  font-family: $serif;
  font-size: calc(14.25vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}

@mixin serif--header-one-line--mobile() {
  font-family: $serif;
  font-size: calc(16.75vh);
  line-height: 0.85em;
  text-transform: uppercase;
  text-align: center;
}
