.c-image-container {
	@include parent-behaviour;
	width:100%;
	height: auto;

	&.is-calc-vh  {
		height:100vh;
		margin-top:0;
		margin-bottom:0;
	}
	
	figure {

		&.is-centered {
			margin-left: auto;
			margin-right: auto;
		}

		&.is-contained {
			max-width: 55%;
		}
		
		&.is-portrait {
			@include col(1/2);
			@include mq($br-medium) {
				@include col(2/3);
				float: none;
				margin-left: auto;
				margin-right: auto;
			}
			float: none;
			margin-left: auto;
			margin-right: auto;
		}

		&.is-square {
			@include col(2/3);
			@include mq($br-medium) {
				@include col(1);
				float: none;
				margin-left: auto;
				margin-right: auto;
			}
			float: none;
			margin-left: auto;
			margin-right: auto;
		}

		&.is-landscape {
			max-width: 100%
;		}
		
		img {
			max-width: 100%;
			max-height: 100%;
			margin: 0px auto;
		}

		figcaption {
			float:none;
			margin-left: auto;
			margin-right: auto;
			display: block;
			text-align: center;
		}
	}
}
