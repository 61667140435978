// Header

.c-header {

    position: fixed;
    left:0;
    top:0;
    bottom:0;
    width:calc(33.33vw);
    height: 100vh;

    z-index: $z-header;

    @include mq($br-medium-large) {
      background-color: #ffffff;
      width: 100%;
      z-index: $z-header-mobile;

      @include transform(translateX(-100%));

      &.is-hidden {
          @include transform(translateX(-100%));
      }

      &.is-visible {
          @include transform(translateX(0));
      }


    }

    @include transition(transform, $slow, $easeOutQuart);

    &.one-line {
      @include serif--header-one-line;
    }

    &.two-line {
      @include serif--header-two-line;
    }

    &.three-line {
      @include serif--header-three-line;
    }

    &.four-line {
      @include serif--header-four-line;
    }

    &.five-line {
      @include serif--header-five-line;
    }

}


html.is-touchdevice {

  .c-header {
      &.one-line {
        @include serif--header-one-line--mobile;
      }

      &.two-line {
        @include serif--header-two-line--mobile;
      }

      &.three-line {
        @include serif--header-three-line--mobile;
      }

      &.four-line {
        @include serif--header-four-line--mobile;
      }

      &.five-line {
        @include serif--header-five-line--mobile;
      }
  }

}


.c-nav {
  // Center this in the height of the container
  position: absolute;
  top: 51%;
  left: 50%;
  @include transform(translate(-50%,-50%));
}

html.is-touchdevice {

  .c-nav {
    top: 1%;
    @include transform(translate(-50%,0));
  }

}

.c-nav__link {
  display: inline;
  text-align: center;
  /*
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0));
  background-size: 3px 3px;
  background-repeat: repeat-x;
  background-position: 0% 85%;

  &:hover,
  &:focus {
    background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1));
  }
  */

}

/*
.show-active-state .c-nav__link {
  &.active {
    background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1));
  }
}
*/

//New idea for underlining
.c-nav__link {

  .c-nav__line {
    display: inline-block;
    line-height: 0.64em;
    border-bottom: 3px solid rgba(0,0,0,0);
    @include transition(border, $fast, $ease);
  }

  &:hover,
  &:focus {
    .c-nav__line {
      border-bottom: 3px solid rgba(0,0,0,1);
    }
  }

}

.show-active-state .c-nav__link {
  &.active {
    .c-nav__line {
      border-bottom: 3px solid rgba(0,0,0,1);
    }
  }
}


.c-btn-toggle-menu {
  @include transform(rotate(90deg));
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;

  @include serif;
  text-align: center;
  position:fixed;
  z-index: 999;
  white-space: nowrap;

  top: 9px;
  left: 38px;

  display:none;

  @include mq($br-medium-large) {
    display: block;
  }

  @include mq($br-medium) {
    //top: 5px;
    left: 30px;
  }

}
