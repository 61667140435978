// Items

// Items have spacings on top and bottom.

.o-item {
	@include parent-behaviour;
	margin-bottom: $large_padding*2;
	margin-top: $large_padding*2;
	@include mq($br-large) {
		margin-bottom: $medium_padding*2;
		margin-top: $medium_padding*2;
	}
	@include mq($br-medium) {
		margin-bottom: $small_padding*2;
		margin-top: $small_padding*2;
	}
}

.o-item--extra-padding {
	margin-bottom: $large_padding*4;
	margin-top: $large_padding*4;
	@include mq($br-large) {
		margin-bottom: $medium_padding*4;
		margin-top: $medium_padding*4;
	}
	@include mq($br-medium) {
		margin-bottom: $small_padding*4;
		margin-top: $small_padding*4;
	}
}

.o-item--center {
	margin-left: auto !important;
	margin-right: auto !important;
}

.o-item--full {
	@include col(1);
}

.o-item--one-third {
	@include col(1/3);
	@include mq($br-medium-large) {
		@include col(1/2);
	}
}

.o-item--two-thirds {
	@include col(2/3);

	@include mq($br-medium-large) {
		@include col(1);
	}
}

.o-item--one-two {
	@include col(1/2);
	@include mq($br-medium-large) {
		@include col(2/3);
	}
}

.o-item--tb-small {
	@include col(1/3);
	@include mq(1200px) {
		@include col(3/8);
	}

	@include mq($br-small) {
		@include col(3/4);
	}
}

.o-item--tb-large {
	@include col(1/2);
	@include mq(1200px) {
		@include col(5/8);
	}

	@include mq($br-small) {
		@include col(1);
	}
}

.o-item--small {	
	@include col(3/8);
	@include mq(1200px) {
		@include col(4/8);
	}

	@include mq($br-small) {
		@include col(5/8);
	}

	&.o-item--portrait,
	&.o-item--square {
		@include col(2/8);
		@include mq(1200px) {
			@include col(3/8);
		}

		@include mq($br-small) {
			@include col(4/8);
		}	
	}
}

.o-item--medium {
	@include col(4/8);
	@include mq(1200px) {
		@include col(5/8);
	}

	@include mq($br-small) {
		@include col(6/8);
	}

	&.o-item--portrait,
	&.o-item--square {
		@include col(3/8);
		@include mq(1200px) {
			@include col(4/8);
		}

		@include mq($br-small) {
			@include col(5/8);
		}	
	}
}

.o-item--large {
	@include col(5/8);
	@include mq(1200px) {
		@include col(6/8);
	}

	@include mq($br-small) {
		@include col(1);
	}

	&.o-item--portrait,
	&.o-item--square {
		@include col(4/8);
		@include mq(1200px) {
			@include col(5/8);
		}

		@include mq($br-small) {
			@include col(1);
		}	
	}
}

.o-item--to-load {
	opacity: 0;
	@include transition(opacity, $fast, $ease);
	&.is-loaded {
		opacity: 1;
	}
}

.js-show-in-gallery {
 	cursor: -webkit-zoom-in;
	cursor: zoom-in;
}

html.no-js {
	.o-item--to-load {
		opacity: 1;
	}
}