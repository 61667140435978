//Project gallery page

.c-project__overlay-close {
	position:absolute;
	padding-top: 18px;
	top: 0;
	left: $large_padding;
    &:hover {
        color: $white;
    }
}

.c-project__overlay-title {
	position:absolute;
	padding-top: 18px;
	top: 0;
	left: 50%;
	@include transform(translateX(-50%));
}

.c-project__overlay-header {
	position: absolute;
	width: 100%;
	height: 60px;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: $z-overlay-button;
}

.c-project__overlay-image-container {
	position: relative;
	//width: 100%;
	width: 100vw;
	height: 100vh;
	//max-height:100vh;
	text-align: center;
	margin: 0; padding: 0;

	cursor: s-resize;
}

.c-project__overlay-image {
	//max-width: 100%;
	//max-height: 100%;
	//margin: 0 auto;

	position: absolute;
	top: 50%;
	left: 50%;
	bottom: 0;
	right: 0;
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: calc(100% - 180px);
	@include transform(translate(-50%, -50%));

	//cursor: s-resize;
}

.c-project__overlay-image-container:hover {
	.c-project__overlay-caption {
		opacity: 1;
	}
}

.c-project__overlay-caption {
	position: absolute;
	left: 50%;
	bottom: 60px;
	@include transform(translateX(-50%));
	@include transition(opacity, $fast, $ease);
	opacity: 0;

}

.c_project__btn {
	position:fixed;
	z-index: 2;
	@include serif;
	line-height: 1em;
	text-align: center;
	white-space: nowrap;

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;


	@include transition(opacity, $fast, $ease);

}

.c_project__btn--close {
	@include transform(rotate(90deg));
    -ms-transform-origin: top right;
    -webkit-transform-origin: top right;
    transform-origin: top right;

    top: 165px;
    right: 8px;

	@include mq($br-medium) {
		top: 135px;
		right: 0px;
	}

}






