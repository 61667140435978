.c-main {
	@include parent-behaviour;
	width: 66.66vw;
	margin-left: 33.33vw;

	@include mq($br-medium-large) {
		width: 100%;
		width: 100vw;
		margin-left: auto;
	}

}