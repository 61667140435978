//General

html {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

html, body {
  width:100%;
  height:100%;
}

body.no-scroll {
	overflow: hidden;
}

body.no-scroll-mobile {
	overflow: hidden;
	position: fixed;
}