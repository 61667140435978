// Typography
// Typographical base selectors. Define base styles on single element selectors here.

body {
  @include sans();
  color: $black;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "tnum"; /* [1] */
  text-rendering: optimizeLegibility;
}

a {
  color: $black;
  text-decoration: none;
  &:hover,
  &:focus,
  &.active {
    color: $black;
  }
}

.o-text a {
  background-image: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,1));
  background-size: 1px 1px;
  background-repeat: repeat-x;
  background-position: 0% 90%;
  &:hover,
  &:focus,
  &.active {
    background-image: linear-gradient(#000, #000);
    background-size: 0 0;
    background-repeat: repeat-x;
    background-position: 0% 90%;
  }
}

p {
  margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  font-size: inherit;
} 

h1 {
  @include serif;
  margin-bottom: 1em;
}

h2 {
  @include sans--bold();
  font-weight: normal; 
  margin-top: 2em;
  margin-bottom: 1em;
}


b, strong {
  @include sans--bold();
  font-weight: normal; 
}

em {
  @include sans--italic();
  font-style: normal;
}

.inverted {
  color: $white;
  a, p, ul, li {
    color: $white;
  }
  a:hover {
    color: $text-gray;
  }
}