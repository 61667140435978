// Mixins

@mixin clear() {
  &:before,
  &:after {
      content: " ";
      display: table;
  }

  &:after {
      clear: both;
  }
}

@mixin parent-behaviour() {
    position:relative;
    overflow:hidden;
    display:block;
    @include clear;
}

@mixin child-overlay-behaviour() {
    position:absolute;
    top:0; bottom:0; left:0; right:0;
    width:100%;
    height:100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

@mixin icon-background($icon) {
    background-image: url($icon);
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin mq($media) {
    @if $media == webkit {
        @media screen and (-webkit-min-device-pixel-ratio: 0) {
            @content;
        }
    }
 
    @else if $media == retina {
        @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
            @content;
        }
    }
 
    @else {
        @media screen and (max-width: $media) {
            @content;
        }
    }
}

@mixin mq-min-w($min) {
    @media screen and (min-width: $min) {
        @content;
    }
}
 
@mixin mq-max-h($max) {
    @media screen and (max-height: $max) {
        @content;
    }
}
 
@mixin mq-min-h($min) {
    @media screen and (min-height: $min) {
        @content;
    }
}

@mixin mq-min-max($min, $max) {
    @media screen and (min-width: $min) and (max-width: $max) {
        @content;
    }
}
 
@mixin mq-min-w-max-h($min, $max) {
    @media screen and (min-width: $min) and (max-height: $max) {
        @content;
    }
}

@mixin transition ($property, $duration, $timing_function, $delay: 0s) {
  -webkit-transition-property:        $property;
  -webkit-transition-duration:        $duration;
  -webkit-transition-timing-function: $timing_function;
  -webkit-transition-delay:           $delay;

  -moz-transition-property:           $property;
  -moz-transition-duration:           $duration;
  -moz-transition-timing-function:    $timing_function;
  -moz-transition-delay:              $delay;

  -o-transition-property:             $property;
  -o-transition-duration:             $duration;
  -o-transition-timing-function:      $timing_function;
  -o-transition-delay:                $delay;

  -ms-transition-property:            $property;
  -ms-transition-duration:            $duration;
  -ms-transition-timing-function:     $timing_function;
  -ms-transition-delay:               $delay;

  transition-property:                $property;
  transition-duration:                $duration;
  transition-timing-function:         $timing_function;
  transition-delay:                   $delay;
}

@mixin reset-transitions(){
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation:    $animation;
  -o-animation:      $animation;
  animation:         $animation;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin default-transition($property: all, $delay: 0s){
  @include transition($property, 200ms, cubic-bezier(0.2, 0.3, 0.25, 0.9), $delay);
}

@mixin font-loading-transition(){
  opacity: 0;
  @include default-transition(opacity);
  .fonts-loaded &, .no-js &{
    opacity: 1;
  }
}

@mixin box-shadow ($string) {
  -webkit-box-shadow: $string;
  -moz-box-shadow:    $string;
  box-shadow:         $string;
}

@mixin box-sizing ($type: border-box) {
  -webkit-box-sizing: $type;
  -moz-box-sizing:    $type;
  box-sizing:         $type;
}

@mixin border-radius ($radius: 3px){
  -webkit-border-radius:  $radius;
  -moz-border-radius:     $radius;
  border-radius:          $radius;
}

@mixin border-top-radius ($radius: 3px){
  -webkit-border-top-left-radius:   $radius;
  -moz-border-top-left-radius:      $radius;
  border-top-left-radius:           $radius;
  -webkit-border-top-right-radius:  $radius;
  -moz-border-top-right-radius:     $radius;
  border-top-right-radius:          $radius;
}

@mixin transform($transforms){
  -moz-transform:     $transforms;
  -o-transform:       $transforms;
  -ms-transform:      $transforms;
  -webkit-transform:  $transforms;
  transform:          $transforms;
}

@mixin rotate($deg:180deg){
  @include transform(rotate($deg));
  -webkit-backface-visibility: hidden;
}

@mixin remove-appearance(){
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

@mixin ellipsis(){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: to;
}

@mixin placeholder($color){
  ::-webkit-input-placeholder {
      color: $color;
  }
  :-moz-placeholder {
      color: $color;
  }
  ::-moz-placeholder {
      color: $color;
  }
  :-ms-input-placeholder {
      color: $color;
  }
}

@mixin gradient($color, $from, $to){
  background: rgba($color, 0);
  background: -moz-linear-gradient(top, rgba($color,0) $from, rgba($color, 1) $to);
  background: -webkit-gradient(left top, left bottom, color-stop(from, rgba($color, 0)), color-stop($to, rgba($color, 1)));
  background: -webkit-linear-gradient(top, rgba($color, 0) $from, rgba($color, 1) $to);
  background: -o-linear-gradient(top, rgba($color, 0) $from, rgba($color, 1) $to);
  background: -ms-linear-gradient(top, rgba($color, 0) $from, rgba($color, 1) $to);
  background: linear-gradient(to bottom, rgba($color, 0) $from, rgba($color, 1) $to);
}

@mixin responsive($property, $values, $multiplier: 1, $adder: 0, $string: '') {
  @if (type-of($values) == "map") {
    $last-value: 0;
    @each $layout-name in $layouts-name {
      @if map-has-key($values, $layout-name) {
        $value: map-get($values, $layout-name);
        @if $last-value != $value {
          @include media-query($layout-name) {
            #{$property}: unquote(multiply-all($value, $multiplier, $adder) + $string);
          }
          $last-value: $value;
        }
      }
    }
  } @else {
    #{$property}: multiply-all($values, $multiplier);
  }
}

@mixin list-reset {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

@mixin underline {
  border-bottom: 1px solid;
  text-decoration: none;
}

@mixin no-underline {
  border-bottom: 0;
  text-decoration: none;
}

@mixin text-underline-crop($background) {
  text-shadow:  .03em 0 $background, 
                -.03em 0 $background,
                0 .03em $background,
                0 -.03em $background,
                .06em 0 $background,
                -.06em 0 $background,
                .09em 0 $background,
                -.09em 0 $background,
                .12em 0 $background,
                -.12em 0 $background,
                .15em 0 $background,
                -.15em 0 $background;
}

@mixin text-underline($color-bg, $color-text) {
  background-image: linear-gradient($color-text, $color-text);
  background-size: 1px 1px;
  background-repeat: repeat-x;
  background-position: 0% 95%;
}

@mixin text-selection($selection) {
  &::selection {
    @include text-underline-crop($selection);
    
    background: $selection;
  }

  &::-moz-selection {
    @include text-underline-crop($selection);
    
    background: $selection;
  }
}

@mixin link-underline($background, $text, $selection){
  @include text-underline-crop($background);
  @include text-underline($background, $text);
  @include text-selection($selection);

  color: $text;
  text-decoration: none;

  *,
  *:after,
  &:after,
  *:before,
  &:before {
    text-shadow: none;
  }

  &:visited {
    color: $text;
  }
}

$color-text:        #333332;
$color-background:  #fff;
$color-selection:   #b4d5fe;

body {
  font-family: freight-text-pro, georgia, serif;
  font-size: 18px;
  line-height: 1.4;
  margin: 20px;
}

pre {
  background-color: #eee;
  font-size: 16px;
  padding: 20px;  
}

.post a {
  @include link-underline($color-background, $color-text, $color-selection);
}


@mixin font($font-name) {
  $font-settings: map-get($fonts, $font-name);

  @each $key, $value in $font-settings {
    @include responsive($key, $value);
  }
}
