// Layout

// Layout variables

$max_container_width: 1300px;
$max_text_width: 650px;
 
$large_padding: 30px;
$medium_padding: 20px;
$small_padding: 15px;

$large_text_padding: 30px;
$medium_text_padding: 20px;
$small_text_padding: 15px;

// Breakpoints

$br-large:            1336px;
$br-medium-large:     920px;
$br-medium:           768px;
$br-small:            400px;

// Grid

@mixin container() {
  width: 100%;
  max-width: $max_container_width;
  margin-left: auto;
  margin-right: auto;
  padding-left: $large_padding;
  padding-right: $large_padding;
  @include mq($br-large) {
    padding-left: $medium_padding;
    padding-right: $medium_padding;
  }
  @include mq($br-medium) {
    padding-left: $small_padding;
    padding-right: $small_padding;
  }
}

@mixin section() {
  @include parent-behaviour;
  margin-top: $large_padding*2;
  margin-bottom: $large_padding*2;
  @include mq($br-large) {
      margin-top: $medium_padding*2;
      margin-bottom: $medium_padding*2;
  }
  @include mq($br-medium) {
      margin-top: $small_padding*2;
      margin-bottom: $small_padding*2;
  }
}


@mixin section--less-padding() {
  @include parent-behaviour;
  margin-top: $large_padding*1.5;
  margin-bottom: $large_padding*1.5;
  @include mq($br-large) {
      margin-top: $medium_padding*1.5;
      margin-bottom: $medium_padding*1.5;
  }
  @include mq($br-medium) {
      margin-top: $small_padding*1.5;
      margin-bottom: $small_padding*1.5;
  }
}


@mixin section--extra-padding() {
  @include parent-behaviour;
  margin-top: $large_padding*3;
  margin-bottom: $large_padding*3;
  @include mq($br-large) {
      margin-top: $medium_padding*3;
      margin-bottom: $medium_padding*3;
  }
  @include mq($br-medium) {
      margin-top: $small_padding*3;
      margin-bottom: $small_padding*3;
  }
}

@mixin row($inside: false) {
  @if($inside == true) {
    margin-left: -$large_padding/2;
    margin-right: -$large_padding/2;
    @include mq($br-large) {
      margin-left: -$medium_padding/2;
      margin-right: -$medium_padding/2;
    }
    @include mq($br-medium) {
      margin-left: -$small_padding/2;
      margin-right: -$small_padding/2;
    }
  }
  @else {
    @include container();
    padding-left: $large_padding/2;
    padding-right: $large_padding/2;
    @include mq($br-large) {
      padding-left: $medium_padding/2;
      padding-right: $medium_padding/2;
    }
    @include mq($br-medium) {
      padding-left: $small_padding/2;
      padding-right: $small_padding/2;
    }
  }
  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin row-no-padding() {
  @include container();
  padding-left: 0;
  padding-right: 0;

  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin col($width: 1, $padding: true, $centered: false) {
  @if($centered == true) {
    margin-left: auto;
    margin-right: auto;
    display:block;
  }
  @else {
    float: left;
  }
  width: percentage($width);
  @if($padding == true) {
    padding-left: $large_padding/2;
    padding-right: $large_padding/2;
    @include mq($br-large) {
      padding-left: $medium_padding/2;
      padding-right: $medium_padding/2;
    }
    @include mq($br-medium) {
      padding-left: $small_padding/2;
      padding-right: $small_padding/2;
    }
  }
}

@mixin push($width: 0) {
  position: relative;
  left: percentage($width);
}
@mixin pull($width: 0) {
  position: relative;
  right: percentage($width);
}
