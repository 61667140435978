.u-clearfix {
  @include clear;
}

.u-display-block {
	display: block;
}

.u-vertical-center {
  display: table;
  height: 100%;
  width: 100%;
}

.u-vertical-center__content {
  display: table-cell;
  vertical-align: middle;
}

.u-float--left {
	float:left !important;
}

.u-float--mid-left {
  float:left !important;
  margin-left: 6.66vw !important;
}

.u-float--center {
  float: none !important;
	margin-left: auto !important;
	margin-right: auto !important;
}

.u-float--mid-right {
  float:right !important;
  margin-right: 6.66vw !important;
}

.u-float--right {
	float:right !important;
}

