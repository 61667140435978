.o-text {
	max-width: $max_text_width;
	margin-left: auto;
	margin-right: auto;

	@include col(2/3);
	@include mq($br-medium) {
		@include col(1);
		float: none;
		margin-left: auto;
		margin-right: auto;
	}
	float: none;
	margin-left: auto;
	margin-right: auto;

	img {
		margin-top: 1.25em;
		margin-bottom: 1.25em;
	}
}