// Forms
// Forms base selectors

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

input[type="checkbox"],
input[type="radio"] {
	margin-right: 5px;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

form {
  @include parent-behaviour;
}

input,
textarea,
button,
select {
	
  //@include user-select(text);
  padding: 0 5px;
  margin: 0;
  outline: none;
  cursor: pointer;
  border:0;
  border-radius: 0;
  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;

  vertical-align: middle;
  height: auto;
  display:inline-block;
  float:left;

}

input,
textarea {
  border-bottom: 1px solid #000;
}

::-webkit-input-placeholder { color: $text-gray; }
::-moz-placeholder      	  { color: $text-gray; }
:-ms-input-placeholder    	{ color: $text-gray; }
input:-moz-placeholder    	{ color: $text-gray; }

form {

  margin-top: $small_padding;
  width: 50%;

  @include mq($br-medium) {
    width: 75%;
  }

  @include mq($br-small) {
    width: 100%;
  }

	label {
		display:none;
	}
}

.mc-field-group {
	float: none;
  display:block;
  width: 100%;
  height: auto;
  overflow: hidden;
}

input[type="submit"] {
	background-color: #000;
  color: #fff;
	border: none;
	outline: none;
  padding: 5px 10px;
  margin-top: $small_padding;
  margin-bottom: $small_padding;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @include sans--bold();
  display:block;
  width: 100%;
  float:none;

}

#mc-embedded-subscribe-form {
  display:inline-block;
}

#mce-responses {
  float:left;
}

#mc-embedded-subscribe-form input[type="email"] {
  width: 100%;
  text-align: center;
}

#mc-embedded-subscribe-form input[type="submit"]  {
  width: auto;
}

